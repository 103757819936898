import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { hero } from "../js/anims/hero.js";
import { speech } from "../js/anims/speech.js";
import { stickers } from "../js/anims/stickers.js";
import Lenis from 'lenis';

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        document.querySelector('.js-hero') ? hero() : false;
        document.querySelector('.js-speech') ? speech() : false;
        document.querySelector('.js-stickers') ? stickers() : false;
    };
    
    setTimeout(() => {
        const lenis = new Lenis()
        
        lenis.on('scroll', ScrollTrigger.update)
        
        gsap.ticker.add((time)=>{
          lenis.raf(time * 1000)
        })
        
        gsap.ticker.lagSmoothing(0)

        gsap.utils.toArray(".a-fic > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-40px bottom',
                    toggleActions: "play complete complete reset",
                },
                autoAlpha: 0, 
                duration: 1,
                y: 40
            });
        });
    }, 1500)


}, false)
